<template>
  <div class="d-flex mb-4 mr-5 align-items-center justify-content-center">
    <img
      v-if="item.point < item.target"
      src="../assets/images/dashboard-icon.svg"
      class="m-1 mb-2"
      style="width: 3rem"
    />
    <img
      v-else
      src="../assets/images/dashboard-icon-green.svg"
      class="m-1 mb-2"
      style="width: 3rem"
    />
    <div class="ml-2">
      <div>{{ item.text }}</div>
      <h4 class="m-0 mb-1">{{ item.point }}/{{ item.target }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {};
  },
};
</script>