<template>
  <div class="content-wrapper network-wrapper my-3 mx-1">
    <h4>Network</h4>
    <b-row>
      <b-col md="6"
        ><b-form-group label="Company">
          <b-form-select
            v-model="company"
            :options="companies"
            @change="companyChange"
          ></b-form-select> </b-form-group
      ></b-col>
      <b-col md="6">
        <b-form-group label="Employee">
          <div class="d-flex">
            <b-form-select
              v-model="active_user_id"
              :options="employeeListSelect"
              @change="changeActiveUser"
            >
              <template #first>
                <b-form-select-option value="null"
                  >Select a person</b-form-select-option
                >
              </template>
            </b-form-select>
            <!-- <div
              class="d-flex align-items-center justify-content-center"
              style="width: 3rem"
            >
              <b-icon-x-circle-fill
                class="pointer"
                @click="active_user_id = null"
                v-if="active_user_id"
              />
            </div> -->
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group label="Role">
          <b-form-select
            v-model="roleSelected"
            :options="rolesList"
            @change="filterChange"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Area">
          <b-form-select
            v-model="areaSelected"
            :options="areasList"
            @change="filterChange"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Department">
          <b-form-select
            v-model="departmentSelected"
            :options="departmentsList"
            @change="filterChange"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Cluster">
          <b-form-select
            v-model="clusterSelected"
            :options="clustersList"
            @change="filterChange"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="position-relative network-chart" ref="network_chart">
      <div v-for="user in tree" :key="user.id" class="network-row">
        <network-user
          :user="user"
          :active_user="active_user_id"
          :active_user_path="active_user_path"
          :roles="roles"
          :roleSelected="roleSelected"
          :areaSelected="areaSelected"
          :departmentSelected="departmentSelected"
          :clusterSelected="clusterSelected"
          @add-user-coordinate="addUserCoordinate"
          @change-active-user="changeActiveUser"
          @edit-user="editUser"
        />
      </div>

      <svg class="svg-absolute">
        <line
          v-for="(line, index) in lines"
          :key="'lines_' + index"
          class="line"
          :x1="line.x1"
          :y1="line.y1"
          :x2="line.x2"
          :y2="line.y2"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import NetworkUser from "./NetworkUser";
import AxiosService from "../axiosServices/AxiosService";
export default {
  components: { NetworkUser },
  props: ["list", "companies", "roles"],
  service: null,
  created() {
    this.service = new AxiosService("null");
  },

  data() {
    return {
      roleSelected: null,
      rolesList: [{ value: null, text: "Select a role" }],
      areaSelected: null,
      areasList: [{ value: null, text: "Select an area" }],
      departmentSelected: null,
      departmentsList: [{ value: null, text: "Select a department" }],
      clusterSelected: null,
      clustersList: [{ value: null, text: "Select an cluster" }],

      company: "",
      employee: "",
      active_user_id: null,
      user_coordinates: [],
    };
  },
  methods: {
    filterChange() {
      const company = this.company;
      this.company = null;
      this.user_coordinates = [];
      this.$nextTick(() => {
        this.company = company;
        this.active_user_id = null;
      });
    },
    drawSVGLine(rect1, rect2) {
      if (
        //is not retina display
        !window.matchMedia(
          "(-webkit-min-device-pixel-ratio: 1.5),\
            (min--moz-device-pixel-ratio: 1.5),\
            (-o-min-device-pixel-ratio: 3/2),\
            (min-resolution: 1.5dppx)"
        ).matches
      ) {
        var x_length = 12;
        var parent = this.$refs["network_chart"];
        var rectParent = parent.getBoundingClientRect();
        var x1 = rect1.left + rect1.width + x_length - rectParent.left;
        var y1 = rect1.top + rect1.height / 2 - rectParent.top;
        var x2 = rect2.left - x_length - rectParent.left;
        var y2 = rect2.top + rect2.height / 2 - rectParent.top;

        return [
          {
            x1: x1 - x_length,
            y1: y1,
            x2: x1,
            y2: y1,
          },
          {
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2,
          },
          {
            x1: x2,
            y1: y2,
            x2: x2 + x_length,
            y2: y2,
          },
        ];
      } else {
        // is retina display

        x_length = 9.5;
        parent = this.$refs["network_chart"];
        rectParent = parent.getBoundingClientRect();
        x1 = rect1.left + rect1.width + x_length - rectParent.left;
        y1 = rect1.top + rect1.height / 2 - rectParent.top;
        x2 = rect2.left - x_length - rectParent.left;
        y2 = rect2.top + rect2.height / 2 - rectParent.top;

        return [
          {
            x1: x1 - x_length,
            y1: y1,
            x2: x1,
            y2: y1,
          },
          {
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2,
          },
          {
            x1: x2,
            y1: y2,
            x2: x2 + x_length,
            y2: y2,
          },
        ];
      }
    },
    changeActiveUser(id) {
      const company = this.company;
      this.company = null;
      this.user_coordinates = [];
      //Necessario per svuotare lista ed aggiornarla solo con gli user attivi
      //Funziona perchè le coordinate vengono emesse dall'user solo in mounted
      this.$nextTick(() => {
        this.company = company;
        this.active_user_id = id;
      });
    },
    editUser(id) {
      this.$emit("edit-user", id);
      this.$root.$emit("bv::toggle::collapse", "sidebar-network");
    },
    addUserCoordinate(id, rect) {
      this.user_coordinates.push({ id: id, rect: rect });
    },
    companyChange() {
      this.active_user_id = null;
      this.user_coordinates = [];

      this.rolesList.splice(1);
      this.areasList.splice(1);
      this.departmentsList.splice(1);
      this.clustersList.splice(1);

      this.service
        .readCustomEndpoint("Option/UserRole/" + this.company)
        .then((res) => {
          this.rolesList.push(...res);
        });
      this.service
        .readCustomEndpoint("Option/AT_Areas/" + this.company)
        .then((res) => {
          this.areasList.push(...res);
        });
      this.service
        .readCustomEndpoint("Option/AT_Department/" + this.company)
        .then((res) => {
          this.departmentsList.push(...res);
        });
      this.service
        .readCustomEndpoint("Option/AT_Cluster/" + this.company)
        .then((res) => {
          this.clustersList.push(...res);
        });
    },
  },
  mounted() {
    if (this.companies && this.companies.length > 0) {
      this.company = this.companies[0].value;
    }
    this.companyChange();
  },
  computed: {
    employeeList() {
      if (this.list && this.company) {
        return this.list.filter((item) => {
          if (this.company && item.id_company != this.company) {
            return false;
          }
          return true;
        });
      } else {
        return [];
      }
    },
    employeeListSelect() {
      return this.employeeList.map((x) => {
        return { value: x.id, text: x.nome + " " + x.cognome };
      });
    },
    tree() {
      let list = this.employeeList;
      if (!list) return [];
      let map = {},
        node,
        roots = [],
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.id_parent && node.id_parent !== "0") {
          if (list[map[node.id_parent]]) {
            list[map[node.id_parent]].children.push(node);
          }
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    lines() {
      let lines = [];
      if (!this.active_user_id) {
        this.employeeList.forEach((user) => {
          const rect1 = this.user_coordinates.find((x) => {
            return x.id === user.id;
          });
          if (rect1 && user.children) {
            user.children.forEach((child) => {
              const rect2 = this.user_coordinates.find((x) => {
                return x.id === child.id;
              });
              if (rect2) {
                let line_components = this.drawSVGLine(rect1.rect, rect2.rect);
                line_components.forEach((line) => {
                  lines.push(line);
                });
              }
            });
          }
        });
      } else {
        this.employeeList.forEach((user) => {
          if (this.active_user_path.includes(user.id)) {
            const rect1 = this.user_coordinates.find((x) => {
              return x.id === user.id;
            });
            if (rect1 && user.children) {
              user.children.forEach((child) => {
                const rect2 = this.user_coordinates.find((x) => {
                  return x.id === child.id;
                });
                if (rect2) {
                  let line_components = this.drawSVGLine(
                    rect1.rect,
                    rect2.rect
                  );
                  line_components.forEach((line) => {
                    lines.push(line);
                  });
                }
              });
            }
          }
        });
      }
      return lines;
    },
    active_user_path() {
      let path = [];
      let currentId = this.active_user_id;

      while (currentId) {
        let node = this.employeeList.find((x) => {
          return x.id === currentId;
        });
        path.push(currentId);
        if (node.id_parent) {
          currentId = node.id_parent;
        } else {
          currentId = null;
        }
      }

      return path;
    },
  },
};
</script>