<template>
  <div
    class="
      d-flex
      flex-column
      mb-4
      mr-5
      align-items-center
      justify-content-center
      text-center
    "
    :class="{ disabled: !item.status }"
    style="max-width: 7rem"
  >
    <img :src="item.image" class="mt-1 mb-2 circle" style="width: 4rem" />
    <h6 class="m-0 mb-1">{{ item.text }}</h6>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {};
  },
};
</script>