<template>
  <div class="d-flex align-items-start">
    <div
      class="network_user d-flex"
      :class="[
        { active: isActive },
        { active_user: isActiveUser },
        { trasparent: isTrasparent },
        { 'border-selection': isSelected },
      ]"
      ref="user"
    >
      <b-avatar :src="user.avatar_url" variant="light" size="3rem"></b-avatar>
      <div class="ml-2">
        <div>
          <strong>{{ user.nome }} {{ user.cognome }}</strong>
        </div>
        <div class="text-muted network-role">
          {{ getRoleName(user.id_role) }}
        </div>
      </div>
      <div class="d-flex flex-column ml-auto">
        <b-icon-diagram2-fill
          @click="changeActiveUserClick(user.id)"
          class="mb-2 pointer"
          :class="{ 'text-primary': active_user == user.id }"
        ></b-icon-diagram2-fill>
        <b-icon-pencil-square
          @click="editUser(user.id)"
          class="pointer"
        ></b-icon-pencil-square>
      </div>
    </div>
    <div class="children_user_wrapper" v-if="showChildren">
      <NetworkUser
        v-for="child_user in user.children"
        :key="child_user.id"
        :user="child_user"
        :active_user="active_user"
        :active_user_path="active_user_path"
        :roles="roles"
        :roleSelected="roleSelected"
        :areaSelected="areaSelected"
        :departmentSelected="departmentSelected"
        :clusterSelected="clusterSelected"
        @add-user-coordinate="addUserCoordinate"
        @change-active-user="changeActiveUserClick"
        @edit-user="editUser"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [
    "user",
    "active_user",
    "active_user_path",
    "roles",
    "roleSelected",
    "areaSelected",
    "departmentSelected",
    "clusterSelected",
  ],
  name: "NetworkUser",
  data() {
    return {};
  },
  methods: {
    changeActiveUserClick(id) {
      if (this.active_user == id) {
        this.$emit("change-active-user", null);
      } else {
        this.$emit("change-active-user", id);
      }
    },
    editUser(id) {
      this.$emit("edit-user", id);
    },
    addUserCoordinate(id, rect) {
      this.$emit("add-user-coordinate", id, rect);
    },
    getRoleName(idRole) {
      const role = this.roles.find((x) => x.value === idRole);
      return role ? role.text : "";
    },
  },
  mounted() {
    this.$nextTick(() => {
      const rect = this.$refs["user"].getBoundingClientRect();
      this.$emit("add-user-coordinate", this.user.id, rect);
    });
  },
  computed: {
    isActiveUser() {
      return this.user.id == this.active_user;
    },
    isActive() {
      return (
        this.active_user_path.includes(this.user.id) ||
        this.user.id_parent == this.active_user
      );
    },
    isTrasparent() {
      return !(
        this.active_user_path.includes(this.user.id) ||
        this.active_user == null ||
        this.user.id_parent == this.active_user
      );
    },
    isSelected() {
      if (
        this.roleSelected == null &&
        this.areaSelected == null &&
        this.departmentSelected == null &&
        this.clusterSelected == null
      ) {
        return false;
      } else {
        return (
          (this.user.id_role == this.roleSelected ||
            this.roleSelected == null) &&
          (this.user.id_area == this.areaSelected ||
            this.areaSelected == null) &&
          (this.user.id_department == this.departmentSelected ||
            this.departmentSelected == null) &&
          (this.user.id_cluster == this.clusterSelected ||
            this.clusterSelected == null)
        );
      }
    },
    showChildren() {
      return (
        this.active_user == null || this.active_user_path.includes(this.user.id)
      );
    },
  },
};
</script>