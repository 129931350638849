<template>
  <div v-if="loading">Caricamento in corso...</div>
  <div v-else class="mx-3 my-3 ">
    <b-row>
      <b-col class="col-12 mb-4">
        <h4>Generali</h4>
        <b-row>
          <div class="align-items-center mb-2">
            <span class="mr-2">Posizione di partenza</span>
            <GmapAutocomplete
              class="w-100"
              @place_changed="setPlace"
              placeholder="Inserisci il luogo di partenza"
            />
          </div>
        </b-row>
      </b-col>
      <b-col class="col-12 col-md-6 mb-4">
        <h4>Costi</h4>
        <b-row>
          <div class="align-items-center mb-2">
            <span class=" mr-2">Costo Ora</span>
            <b-form-input type="number" v-model="costoOra"></b-form-input>
          </div>
        </b-row>
        <b-row>
          <div class="align-items-center mb-2">
            <span class=" mr-2">Costo Km</span>
            <b-form-input type="number" v-model="costoKm"></b-form-input>
          </div>
        </b-row>
        <h4></h4>
      </b-col>
      <b-col class="col-12 col-md-6 mb-4">
        <h4>Punteggio</h4>
        <Icon
          v-for="n in 5"
          :icon="punteggio < n ? 'ic:round-star-border' : 'ic:round-star'"
        ></Icon>
      </b-col>
      <b-col class="mb-4 col-12 col-md-6 col-lg-4">
        <h4>Giorni liberi</h4>
        <b-row v-for="day in weekDays" :key="day.text">
          <div class="d-flex ">
            <b-form-checkbox v-model="day.enabled"></b-form-checkbox>
            <span>{{ day.text }}</span>
          </div>
        </b-row>
      </b-col>
      <b-col class="mb-4 col-12 col-md-6 col-lg-4">
        <h4>Mezzi che usa</h4>
        <b-row v-for="mezzo in mezzi" :key="mezzo.text">
          <div class="d-flex align-items-center">
            <b-form-checkbox v-model="mezzo.enabled"></b-form-checkbox>
            <Icon :icon="mezzo.icon" />
            <span class="ml-2">{{ mezzo.text }}</span>
          </div>
        </b-row>
      </b-col>
      <b-col class="mb-4 col-12 col-md-6 col-lg-4">
        <h4>Abilità e strumenti</h4>
        <b-row v-for="tool in tools" :key="tool.text">
          <div class="d-flex align-items-center">
            <b-form-checkbox v-model="tool.enabled"></b-form-checkbox>
            <Icon :icon="tool.icon" />
            <span class="ml-2">{{ tool.text }}</span>
          </div>
        </b-row>
      </b-col>
      <b-col class="col-12 mb-4">
        <h4>Area di lavoro</h4>
        <b-row>
          <div
            class="w-full mb-2 d-flex justify-content-end align-items-center"
          >
            <b-button @click="aggiungiAreaDiLavoro">Aggiungi +</b-button>
          </div>
        </b-row>
        <b-row>
          <div
            v-for="(area, index) in areaDiLavoro"
            :key="index"
            class="w-100 d-flex mb-2"
          >
            <b-form-input type="text" v-model="area.text"></b-form-input>
            <b-button class="ml-4" @click="eliminaAreaDiLavoro(index)"
              >Rimuovi</b-button
            >
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="m-0 p-0">
      <b-col class="col-12 p-0 m-0">
        <div class="w-100 d-flex justify-content-end align-items-center">
          <b-button v-if="loading" disabled squared class="big-button"
            >Salvataggio in corso...</b-button
          >
          <b-button
            v-else
            squared
            class="big-button"
            @click="updateCieSkills(idUtente)"
            >Salva</b-button
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2"
import AxiosService from "../../axiosServices/AxiosService"
export default {
  name: "CaratteristicheEStrumentazioneDelCie",
  components: {
    Icon,
  },
  props: {
    idUtente: Number,
  },
  created() {
    this.getCieSkills(this.idUtente)
  },
  data() {
    return {
      loading: false,

      weekDays: [],
      mezzi: [],
      tools: [],
      areaDiLavoro: [
        {
          text: "",
        },
      ],
      costoOra: 0,
      costoKm: 0,
      punteggio: 4,

      posizioneHome: null,
    }
  },
  methods: {
    setPlace(place) {
      console.log(
        "🚀 ~ file: CaratteristicheEStrumentazioneDelCie.vue:147 ~ setPlace ~ place:",
        place
      )
      this.posizioneHome = place
    },
    updateCieSkills(idUtente) {
      this.loading = true
      const service = new AxiosService("DossierManager/UpdateCieSkills")

      service
        .update({
          weekDays: this.weekDays,
          mezzi: this.mezzi,
          tools: this.tools,
          areaDiLavoro: this.areaDiLavoro,
          costoOra: this.costoOra,
          costoKm: this.costoKm,
          punteggio: this.punteggio,
          id: idUtente,
          posizione_home: this.posizioneHome,
        })
        .then((res) => {
          console.log(res)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getCieSkills(idUtente) {
      console.log(
        "🚀 ~ file: CaratteristicheEStrumentazioneDelCie.vue:116 ~ getCieSkills ~ idUtente:",
        idUtente
      )
      this.loading = true
      const service = new AxiosService(
        "DossierManager/GetCieSkills/" + idUtente
      )

      service
        .read()
        .then((res) => {
          this.weekDays = res.weekDays
          this.tools = res.tools
          this.mezzi = res.mezzi
        })
        .finally(() => {
          this.loading = false
        })
    },

    aggiungiAreaDiLavoro() {
      this.areaDiLavoro.push({
        text: "",
      })
    },
    eliminaAreaDiLavoro(index) {
      this.areaDiLavoro.splice(index, 1)
    },
  },
}
</script>
